import React from 'react';
import styled from "styled-components";

function MainHeader(props) {
  return (
    <MainHeaderWrapper>
      {props.children}
    </MainHeaderWrapper>
  )
}

export default MainHeader;

const MainHeaderWrapper = styled.header`
width: 100vw;
height: 4rem;
display: flex;
justify-content: center;
align-items: center;
position: fixed;
top: 0;
left: 0;
background: white;
box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
z-index: 5;
`;
import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";

function CorporateInformation() {
  return (
    <React.Fragment>
      <CorporateInformationPageWrapper>
        <TitleWrapper>
          <h2>Corporate information</h2>
        </TitleWrapper>
        <CompanyProfileWrapper>
          <h4>Company profile</h4>
          <ProfileContentWrapper>
            <NameValuePairWrapper>
              <NameWrapper>
                <p>Company name</p>
              </NameWrapper>
              <ValueWrapper>
                <p>Kenimo Inc.</p>
              </ValueWrapper>
            </NameValuePairWrapper>
            <NameValuePairWrapper>
              <NameWrapper>
                <p>Business</p>
              </NameWrapper>
              <ValueWrapper>
                <p>Sales and planning of Japanese Kimono</p>
              </ValueWrapper>
            </NameValuePairWrapper>
            <NameValuePairWrapper>
              <NameWrapper>
                <p>History</p>
              </NameWrapper>
              <ValueWrapper>
                <p>Founded in 2022</p>
              </ValueWrapper>
            </NameValuePairWrapper>
            <NameValuePairWrapper>
              <NameWrapper>
                <p>Co - founder</p>
              </NameWrapper>
              <ValueWrapper>
                <p>Koju Furuta</p>
                <p>Ken Nakajima</p>
              </ValueWrapper>
            </NameValuePairWrapper>
            {/* <NameValuePairWrapper>
              <NameWrapper>
                <p>Office</p>
              </NameWrapper>
              <ValueWrapper>
                <p>80 Mikura-cho Sanjo Karasuma Nishiiru, Nakagyo-ku Kyoto-shi 604-8166</p>
              </ValueWrapper>
            </NameValuePairWrapper> */}
            {/* <NameValuePairWrapper>
              <NameWrapper>
                <p>Capital</p>
              </NameWrapper>
              <ValueWrapper>
                <p>5 million yen</p>
              </ValueWrapper>
            </NameValuePairWrapper> */}
          </ProfileContentWrapper>
        </CompanyProfileWrapper>
      </CorporateInformationPageWrapper>
      <Footer>
        <KENIMOWrapper>
          <Link to={"/"} >KENIMO</Link>
        </KENIMOWrapper>
        <LawWrapper>
          <Link to={"/corporateinformation"} >Corporate information</Link>
          <Link to={"/privacypolicy"} >Privacy policy</Link>
        </LawWrapper>
      </Footer>
  </React.Fragment>
  )
}

export default CorporateInformation;

const CorporateInformationPageWrapper = styled.div`
display: flex;
justify-content: center;
flex-direction: column;
background-color: #F8F8F7;
`;

const TitleWrapper = styled.div`
width: 70%;
display: flex;
justify-content: center;
flex-direction: column;
margin: auto;
h2{
margin-top: 4rem;
color: black;
font-family: 'Futura';
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 30px;
text-align: center;
}
`;

const CompanyProfileWrapper = styled.div`
width: 90%;
max-width: 600px;
margin: auto;
display: flex;
justify-content: center;
flex-direction: column;
background-color: white;
margin-top: 2rem;
margin-bottom: 8rem;
border-radius: 5px;
box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
h4{
margin-top: 2rem;
color: black;
font-family: 'Futura';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 30px;
text-align: center;
}
`;

const ProfileContentWrapper = styled.div`
display: flex;
justify-content: center;
flex-direction: column;
`;

const NameValuePairWrapper = styled.div`
display: flex;
justify-content: center;
flex-direction: column;
`;

const NameWrapper = styled.div`
text-align: left;
padding-left: 1rem;
`;

const ValueWrapper = styled.div`
padding-left: 1rem;
text-align: left;
color: #737373;
display: flex;
flex-direction: row;
gap: 1rem;
`;

const Footer = styled.div`
background-color: #19110B;
color: white;
`;

const KENIMOWrapper = styled.div`
display: flex;
justify-content: center;
border-style: none none solid none;
border-width: 0.01rem;
border-color: #392D23;
a{
text-decoration: none;
color: white;
font-family: 'Futura';
font-style: normal;
font-weight: 500;
font-size: small;
margin: 0;
padding: 0.5rem;
}
`;

const LawWrapper = styled.div`
display: flex;
justify-content: center;
flex-direction: row;
a{
text-decoration: none;
color: white;
font-family: 'Futura';
font-style: normal;
font-weight: 500;
font-size: small;
margin: 0;
padding: 0.5rem;
}
`;
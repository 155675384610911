import React from "react";
import styled from "styled-components";
import { Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Consultation from "./pages/Consultation";
import MainNavigation from "./components/Navigation/MainNavigation";
import CorporateInformation from "./pages/CorporateInformation";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function App() {

  let route;
  route = (
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/consultation" element={<Consultation/>} />
      <Route path="/corporateinformation" element={<CorporateInformation/>} />
      <Route path="/privacypolicy" element={<PrivacyPolicy/>} />
    </Routes>
  );

  return (
    <div className="App">
      <MainNavigation/>
      <MainWrapper>
        {route}
      </MainWrapper>
    </div>
  );
}

export default App;

const MainWrapper = styled.main`
width: 100%;
margin-top: 4rem;
`;

import React from 'react';
import styled from "styled-components";
import { Link } from "react-router-dom";

import MainHeader from './MainHeader';
// import HamburgerMenuButton from '../UIElements/HamburgerMenuButton';

function MainNavigation() {
  return (
    <MainHeader>
      <TitleWrapper>
        <Link to = "/">KENIMO</Link>
      </TitleWrapper>
    </MainHeader>
  )
}

export default MainNavigation;

const TitleWrapper = styled.div`
color: black;
display: flex;
justify-content: center;
a{
display: flex;
justify-content: center;
text-decoration: none;
text-align: center;
color: black;
font-size: 1.75rem;
font-family: "Futura";
font-style: normal;
font-weight: 500;
}
`;
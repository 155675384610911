import React from 'react';
import { Link } from "react-router-dom";
import styled, {keyframes} from 'styled-components';

import kimonoImage from "../assets/Kimono-itself-min.webp";
import kimonoBackground from "../assets/Kimono-new-Background-min.webp";
import symbolKimono from "../assets/Kenimo-Description1.webp";
import makeKimono from "../assets/Kenimo-Description2.webp";
import onlineMeeting from "../assets/Kimono-online-meeting.webp";
import selectDesign from "../assets/selectDesign.webp";
import checkProgress from "../assets/checkOnline.webp";

function Home() {

  return (
  <React.Fragment>
    <TopWrapper>
      <TitleWrapper>
        <ButtonsWrapper invisible>
          <button>Tap</button>
          <button>Tap</button>
        </ButtonsWrapper>
        <TextWrapper>
          <h2>Custom Made</h2>
          <h1>KIMONO</h1>
        </TextWrapper>
        <ButtonsWrapper>
          <Link to={"/consultation"} >Design your Kimono For Free</Link>
          {/* <Button>Learn Order Flow</Button> */}
        </ButtonsWrapper>
      </TitleWrapper>
    </TopWrapper>
    <DescriptionPageWrapper symbol >
      <DescriptionWrapper symbol >
        <h2>Symbol of Japanese culture</h2>
        <p>
          Kimono, which is often worn at official events in modern society, is a work of art representing 
          Japanese culture, filled with the unique values 
          and aesthetic sense of Japanese people.
        </p>
      </DescriptionWrapper>
      <DescriptionImgWrapper symbol >
        <Img src = {symbolKimono} />
      </DescriptionImgWrapper>
    </DescriptionPageWrapper>
    <DescriptionPageWrapper>
      <DescriptionWrapper>
        <h2>What We Do</h2>
        <p>
          Kenimo is the tailor that offers the highest quality custom-made Kimono 
          only through online procedures.
        </p>
      </DescriptionWrapper>
      <DescriptionImgWrapper>
        <Img src = {makeKimono} />
      </DescriptionImgWrapper>
    </DescriptionPageWrapper>
    <OrderFlowWrapper>
      <h2>Order Flow</h2>
      <StepWrapper>
        <StepXWrapper>
          <FlowImgWrapper>
            <Img src = {onlineMeeting} />
          </FlowImgWrapper>
          <FlowCard>
            <h3>Step1</h3>
            <h2>Online Meeting</h2>
            <p>Tell us your favorite color and design, body size, situation where you use Kimono, and so on.</p>
          </FlowCard>
        </StepXWrapper>
        <StepXWrapper>
          <FlowImgWrapper>
            <Img src = {selectDesign} />
          </FlowImgWrapper>
          <FlowCard>
            <h3>Step2</h3>
            <h2>Select design</h2>
            <p>We submit you a rough sketch reflecting your preferences. We can re-design several times until you are satisfied with.</p>
          </FlowCard>
        </StepXWrapper>
        <StepXWrapper>
          <FlowImgWrapper>
            <Img src = {checkProgress} />
          </FlowImgWrapper>
          <FlowCard>
            <h3>Step3</h3>
            <h2>Check progress</h2>
            <p>We will regularly inform you of the manufacturing process of Kimono by email, and ship the final product to your home.</p>
          </FlowCard>
        </StepXWrapper>
      </StepWrapper>
    </OrderFlowWrapper>
    <Footer>
      <KENIMOWrapper>
        <h5>KENIMO</h5>
      </KENIMOWrapper>
      <LawWrapper>
        <Link to={"/corporateinformation"} >Corporate information</Link>
        <Link to={"/privacypolicy"} >Privacy policy</Link>
      </LawWrapper>
    </Footer>
  </React.Fragment>
  )
}

export default Home;

// --- Animation --- //

const textslide = keyframes`
0% {
transform: translateX(-100px);
}

100% {
transform: translateX(0px);
}
`;

const textfade = keyframes`
0% {
opacity: 0;
transform: translateY(0px);
}

80% {
opacity: 0.5;
transform: translateY(0px);
}

100% {
opacity: 1;
transform: translateY(0px);
}
`;


// --- Top part --- //

const TopWrapper = styled.div`
display: flex;
justify-content: center;
width: 100vw;
height: calc(100vh - 4rem);
box-sizing: border-box;
background: url(${kimonoBackground}) no-repeat center fixed;
background-size: cover;
animation: ${textfade} 1s 1s forwards ease-in-out;
opacity: 0;
`;

const TitleWrapper = styled.div`
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
width: 100vw;
max-width: 600px;
height: calc(100vh - 4rem);
margin: 0;
padding: 0;
h1,h2{
color: white;
font-family: "Futura";
font-style: normal;
font-weight: 500;
margin: 0;
}
background: url(${kimonoImage}) no-repeat center;
background-size: 100% auto
`;

const TextWrapper = styled.div`
display: flex;
justify-content: center;
flex-direction: column;
gap: 10px;
align-items: center;
`;

const ButtonsWrapper = styled.div`
display: flex;
visibility:  ${(props) => (props.invisible ? "hidden" : "visible")};
justify-content: center;
flex-direction: column;
gap: 20px;
margin-top: 8rem;
animation: ${textfade} 1s 1s forwards ease-in-out, ${textslide} 1s 1s forwards ease-in-out;
opacity: 0;
a{
box-sizing: border-box;
width: 320px;
height: 60px;
display: flex;
justify-content: center;
align-items: center;
text-decoration: none;
font-family: 'Futura';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 16px;
color: #FFFFFF;
background: #19110B;
border: 1.5px solid #FFFFFF;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 30px;
}
@media screen and (min-width: 840px) {
flex-direction: row;
margin-top: 10rem;
}
`;

/* const Button = styled.button`
box-sizing: border-box;
width: 320px;
height: 60px;
display: flex;
justify-content: center;
align-items: center;
font-family: 'Futura';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 16px;
text-align: center;
color: #19110B;
background: white;
border: 1.5px solid #19110B;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 30px;
`; */

// --- Symbol of Japanse culture part --- //
const DescriptionPageWrapper = styled.div`
width: 100vw;
display: flex;
justify-content: center;
flex-direction: column;
box-sizing: border-box;
background-color: ${(props) => (props.symbol ? "white" : "black")};
@media screen and (min-width: 600px) {
flex-direction: row;
background-color: ${(props) => (props.symbol ? "#F8F8F7" : "black")};
}
`;

const DescriptionWrapper = styled.div`
max-width: 600px;
display: flex;
justify-content: center;
flex-direction: column;
gap: 10px;
align-items: center;
padding-top: 2rem;
padding-bottom: 2rem;
h2{
color: ${(props) => (props.symbol ? "black" : "white")};
font-family: 'Futura';
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 16px;
text-align: center;
}
p{
width: 85%;
font-family: 'Futura';
font-style: italic;
font-weight: 500;
font-size: 17px;
line-height: 20px;
color: ${(props) => (props.symbol ? "#737373" : "#A6A6A6")};
}
@media screen and (min-width: 600px) {
p{
line-height: 28px;
}
}
`;

const DescriptionImgWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100vw;
max-width: 600px;
height: ${(props) => (props.symbol ? "100vw" : "68vw")}100vw;
margin: 0;
padding: 0;
background-color:  ${(props) => (props.symbol ? "#F8F8F7" : "black")};
@media screen and (min-width: 600px) {
width: 200vw;
height: 85vh;
}
`;

const Img = styled.img`
width: 100%;
height: auto;
`;

// --- Order Flow Part --- //
const OrderFlowWrapper = styled.div`
padding-top: 4rem;
box-sizing: border-box;
background-color: #F8F8F7;
padding-bottom: 8rem;
h2{
color: black;
font-family: 'Futura';
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 16px;
text-align: center;
margin: 0;
}
`;

const StepWrapper = styled.div`
margin-top: 4rem;
flex-direction: column;
box-sizing: border-box;
display: flex;
justify-content: center;
align-items: center;
@media screen and (min-width: 1070px) {
flex-direction: row;
gap: 1rem;
}
`;

const StepXWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`;

const FlowImgWrapper = styled.div`
display: flex;
justify-content: center;
width: 350px;
height: 350px;
`;

const FlowCard = styled.div`
width: 250px;
height: 300px;
display: flex;
justify-content: center;
flex-direction: column;
gap: 1rem;
font-style: italic;
h2{
margin-top: 1rem;
font-style: italic;
}
h3{
color: black;
font-style: italic;
font-family: 'Futura';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 16px;
text-align: center;
margin: 0;
}
p{
line-height: 24px;
color: #737373;
}
`;

const Footer = styled.div`
background-color: #19110B;
color: white;
h5{
font-family: 'Futura';
font-style: normal;
font-weight: 500;
margin: 0;
padding: 0.5rem;
}
`;

const KENIMOWrapper = styled.div`
display: flex;
justify-content: center;
border-style: none none solid none;
border-width: 0.01rem;
border-color: #392D23;
`;

const LawWrapper = styled.div`
display: flex;
justify-content: center;
flex-direction: row;
a{
text-decoration: none;
color: white;
font-family: 'Futura';
font-style: normal;
font-weight: 500;
font-size: small;
margin: 0;
padding: 0.5rem;
}
`;
import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { InlineWidget } from "react-calendly";

function Consultation() {
  return (
    <React.Fragment>
      <ConsultationPageWrapper>
        <TitleWrapper>
          <h2>Schedule Your Free Call with Kenimo</h2>
        </TitleWrapper>
        <CalendlyWrapper phone>
          <InlineWidget 
            url="https://calendly.com/kenimo/kimono-free-consultation"
            styles={{
              minWidth: "320px",
              minHeight: '1000px'
            }}
           />
        </CalendlyWrapper>
        <CalendlyWrapper>
          <InlineWidget 
            url="https://calendly.com/kenimo/kimono-free-consultation"
            styles={{
              minWidth: "1000px",
              minHeight: '1000px'
            }}
           />
        </CalendlyWrapper>
      </ConsultationPageWrapper>
      <Footer>
        <KENIMOWrapper>
          <Link to={"/"} >KENIMO</Link>
        </KENIMOWrapper>
        <LawWrapper>
          <Link to={"/corporateinformation"} >Corporate information</Link>
          <Link to={"/privacypolicy"} >Privacy policy</Link>
        </LawWrapper>
      </Footer>
  </React.Fragment>
  )
}

export default Consultation;

const ConsultationPageWrapper = styled.div`
display: flex;
justify-content: center;
flex-direction: column;
background-color: #F8F8F7;
`;

const TitleWrapper = styled.div`
width: 70%;
display: flex;
justify-content: center;
flex-direction: column;
margin: auto;
h2{
margin-top: 4rem;
color: black;
font-family: 'Futura';
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 30px;
text-align: center;
}
`;

const CalendlyWrapper = styled.div`
margin-top: 2rem;
display:  ${(props) => (props.phone ? "flex" : "none")};
justify-content: center;
@media screen and (min-width: 1000px) {
display:  ${(props) => (props.phone ? "none" : "flex")};
margin-top: 0rem;
}
`;

const Footer = styled.div`
background-color: #19110B;
color: white;
`;

const KENIMOWrapper = styled.div`
display: flex;
justify-content: center;
border-style: none none solid none;
border-width: 0.01rem;
border-color: #392D23;
a{
text-decoration: none;
color: white;
font-family: 'Futura';
font-style: normal;
font-weight: 500;
font-size: small;
margin: 0;
padding: 0.5rem;
}
`;

const LawWrapper = styled.div`
display: flex;
justify-content: center;
flex-direction: row;
a{
text-decoration: none;
color: white;
font-family: 'Futura';
font-style: normal;
font-weight: 500;
font-size: small;
margin: 0;
padding: 0.5rem;
}
`;